import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { DemoMode } from '@dougs/company/dto';
import { AppConfig } from '@dougs/core/config';
import { JobDto } from '@dougs/core/job-dto';

@Injectable({
  providedIn: 'root',
})
export class CompanyDemoHttpService {
  constructor(private readonly http: HttpClient) {}

  hasDemoMode(companyId: number): Observable<boolean> {
    return this.http
      .get<DemoMode>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/demo-mode/is-active`)
      .pipe(map((demoMode) => demoMode.isActive));
  }

  activateDemoMode(companyId: number): Observable<JobDto> {
    return this.http.post<JobDto>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/demo-mode/activate`,
      {},
    );
  }

  deactivateDemoMode(companyId: number): Observable<void> {
    return this.http.post<void>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/demo-mode/remove`, {});
  }
}
