import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { JobDto } from '@dougs/core/job-dto';

@Injectable({
  providedIn: 'root',
})
export class JobApiHttpService {
  constructor(private readonly http: HttpClient) {}

  get(uuid: string): Observable<JobDto> {
    return this.http.get<JobDto>(`${AppConfig.settings.legacyApiServerUrl}/proxy-job-api/${uuid}`, {
      context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),
    });
  }
}
