import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Company, Establishment, ProvisionOfPremises } from '@dougs/company/dto';
import { AppConfig } from '@dougs/core/config';
import { Job } from '@dougs/core/job-dto';
import { Subscription } from '@dougs/subscription/dto';

@Injectable({
  providedIn: 'root',
})
export class CompanyHttpService {
  constructor(private readonly http: HttpClient) {}

  updateCompany(company: Partial<Company>): Observable<Company> {
    return this.http.post<Company>(`${AppConfig.settings.legacyApiServerUrl}/companies/${company.id}`, company);
  }

  getCompany(companyId: number): Observable<Company> {
    return this.http.get<Company>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}`);
  }

  getCompanyAndRefreshSession(companyId: number): Observable<Company> {
    return this.http.get<Company>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}`, {
      params: { refreshSession: true },
    });
  }

  refreshCompanyFromSiren(companyId: number, siren?: string): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/actions/refresh-data-from-siren`,
      { siren },
    );
  }

  refreshCompanyEstablishmentFromSiren(companyId: number): Observable<Establishment[]> {
    return this.http.post<Establishment[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/actions/refresh-establishments-from-siren`,
      {},
    );
  }

  resendCompanyStatusProject(
    companyId: number,
    attachmentIds: number[],
    message: string,
    subject: string,
  ): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/actions/resend-status-projects-email`,
      { message, subject, fileAttachmentIds: attachmentIds },
    );
  }

  updateResiliationReason(companyId: number, resiliationReason: string): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/actions/update-resiliation-reason`,
      {
        resiliationReason,
      },
    );
  }

  updateFreeMonthCount(companyId: number, freeMonthCount: number): Observable<Subscription> {
    return this.http.post<Subscription>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/subscription/actions/update-free-month-count`,
      {
        freeMonthCount,
      },
    );
  }

  buildAccountingLines(companyId: number): Observable<Job> {
    return this.http.post<Job>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/actions/build-accounting-lines`,
      {},
    );
  }

  createProvisionOfPremisesTask(companyId: number, taskData: ProvisionOfPremises): Observable<ProvisionOfPremises> {
    return this.http.post<ProvisionOfPremises>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/actions/create-provision-of-premises-task`,
      taskData,
    );
  }

  createTaxAppointmentTask(companyId: number, taskData: { message: string }): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/actions/create-tax-appointment-task`,
      taskData,
    );
  }

  removeFlag(companyId: number, flag: string): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/actions/remove-flag`,
      {
        flag,
      },
    );
  }

  addFlag(companyId: number, flag: string): Observable<Company> {
    return this.http.post<Company>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/actions/add-flag`, {
      flag,
    });
  }

  automateActivationTask(companyId: number): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/actions/automate-activation-task`,
      {},
    );
  }
}
