import { Injectable } from '@angular/core';
import { Observable, retry, timer } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Job } from '@dougs/core/job-dto';
import { FlashMessagesService } from '@dougs/ds';
import { JobHttpService } from './job.http';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  constructor(
    private readonly jobHttpService: JobHttpService,
    private readonly flashMessagesService: FlashMessagesService,
  ) {}

  handleJob(job: Job): Observable<Job> {
    return this.jobHttpService.get(job.uuid).pipe(
      tap((job: Job) => {
        if (!job.handledAt) {
          throw `job not handled yet`;
        }
        if (job.metadata.error) {
          this.flashMessagesService.show(job.metadata.error.userMessage, {
            timeout: 5000,
            type: 'error',
          });
          throw job.metadata.error;
        } else if (job.metadata.message) {
          this.flashMessagesService.show(job.metadata.message.userMessage, {
            timeout: job.metadata.message.duration || 5000,
            type: job.metadata.message.type,
          });
        }
      }),
      retry({
        delay: (error) => {
          if (error === 'job not handled yet') {
            return timer(1000);
          }
          throw error;
        },
      }),
    );
  }
}
