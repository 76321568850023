import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { JobApiService } from '@dougs/core/job';
import { JobDto } from '@dougs/core/job-dto';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { CompanyDemoHttpService } from '../http/company-demo.http';

interface CompanyDemoState {
  hasDemoMode: boolean;
  isSynchronizing: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CompanyDemoStateService extends StateService<CompanyDemoState> {
  hasDemoMode$: Observable<boolean> = this.select((state) => state.hasDemoMode);
  isSynchronizing$: Observable<boolean> = this.select((state) => state.isSynchronizing);

  constructor(
    private readonly companyDemoHttpService: CompanyDemoHttpService,
    private readonly logger: LoggerService,
    private readonly jobApiService: JobApiService,
  ) {
    super();
  }

  async refreshDemoMode(companyId: number): Promise<void> {
    try {
      const hasDemoMode: boolean = await lastValueFrom(this.companyDemoHttpService.hasDemoMode(companyId));
      this.setState({
        hasDemoMode,
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async activateDemoMode(companyId: number): Promise<any> {
    try {
      const job: JobDto = await lastValueFrom(this.companyDemoHttpService.activateDemoMode(companyId));
      if (job) {
        this.setState({
          isSynchronizing: true,
        });
        await lastValueFrom(this.jobApiService.handleJob(job, 5));
        this.setState({
          hasDemoMode: true,
          isSynchronizing: false,
        });
      }
    } catch (e) {
      this.logger.error(e);
    }
  }

  async deactivateDemoMode(companyId: number): Promise<any> {
    try {
      await lastValueFrom(this.companyDemoHttpService.deactivateDemoMode(companyId));
      this.setState({
        hasDemoMode: false,
      });
    } catch (e) {
      this.logger.error(e);
    }
  }
}
