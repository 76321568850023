import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { AppConfig } from '@dougs/core/config';

@Injectable({
  providedIn: 'root',
})
export class SearchCompanyDataHttpService {
  constructor(private readonly http: HttpClient) {}

  searchCompanyData(searchText: string): Observable<Company[]> {
    return this.http.get<Company[]>(`${AppConfig.settings.legacyApiServerUrl}/company-data`, {
      params: new HttpParams().append('q', searchText),
    });
  }

  searchCompanyDataFromSiren(siren: string): Observable<Company> {
    return this.http.get<Company>(`${AppConfig.settings.legacyApiServerUrl}/company-data-from-siren`, {
      params: new HttpParams().append('siren', siren),
    });
  }
}
