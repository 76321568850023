import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { CompanyActivity } from '@dougs/company/dto';
import { Attachment } from '@dougs/core/files';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { CompanyActivityHttpService } from './company-activity.http';

interface CompanyActivityState {
  companyActivities: CompanyActivity[];
  editedCompanyActivity: CompanyActivity | null;
}

@Injectable({
  providedIn: 'root',
})
export class CompanyActivityStateService extends StateService<CompanyActivityState> {
  readonly companyActivities$: Observable<CompanyActivity[]> = this.select((state) =>
    this.sortCompanyActivities(state.companyActivities),
  );
  readonly companyActivitiesWithJQPA$: Observable<CompanyActivity[]> = this.select((state) =>
    this.sortCompanyActivities(state.companyActivities).filter((companyActivity) => companyActivity.isJqpaNeeded),
  );
  readonly editedCompanyActivity$: Observable<CompanyActivity | null> = this.select(
    (state) => state.editedCompanyActivity,
  );

  constructor(
    private readonly logger: LoggerService,
    private readonly companyActivityHttpService: CompanyActivityHttpService,
  ) {
    super();
  }

  async refreshCompanyActivities(companyId: number): Promise<void> {
    try {
      this.setState({
        companyActivities: this.getAndSetCacheState('companyActivities', companyId)
          ? this.state.companyActivities
          : await lastValueFrom(this.companyActivityHttpService.getCompanyActivities(companyId)),
      });
    } catch (e) {
      this.logger.error(e);
      this.clearCache('companyActivities');
    }
  }

  async deleteCompanyActivity(companyActivity: CompanyActivity): Promise<void> {
    try {
      await lastValueFrom(this.companyActivityHttpService.deleteCompanyActivity(companyActivity));
      this.setState({
        editedCompanyActivity: null,
        companyActivities: this.state.companyActivities.filter(
          (companyActivityIterated: CompanyActivity) => companyActivity.id !== companyActivityIterated.id,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async addCompanyActivity(companyId: number): Promise<CompanyActivity | null> {
    try {
      const companyActivityAdded: CompanyActivity = await lastValueFrom(
        this.companyActivityHttpService.addCompanyActivity(companyId),
      );
      this.setState({
        companyActivities: [...(this.state?.companyActivities || []), companyActivityAdded],
        editedCompanyActivity: companyActivityAdded,
      });

      return companyActivityAdded;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  selectEditedCompanyActivity(companyActivity: CompanyActivity | null): void {
    this.setState({
      editedCompanyActivity: companyActivity,
    });
  }

  async updateCompanyActivity(companyActivity: CompanyActivity): Promise<CompanyActivity | null> {
    try {
      const companyActivityUpdated: CompanyActivity = await lastValueFrom(
        this.companyActivityHttpService.updateCompanyActivity(companyActivity),
      );
      this.setState({
        companyActivities: this.state.companyActivities.map((companyActivityIterated: CompanyActivity) =>
          companyActivity.id === companyActivityIterated.id ? companyActivityUpdated : companyActivityIterated,
        ),
        editedCompanyActivity: null,
      });

      return companyActivityUpdated;
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }

  async addCompanyActivityAttachments(companyActivity: CompanyActivity, files: FileList): Promise<void> {
    try {
      const attachments: Attachment[] = await Promise.all(
        Array.from(files).map((file: File) =>
          lastValueFrom(this.companyActivityHttpService.addCompanyActivityAttachment(companyActivity, file)),
        ),
      );
      this.setState({
        companyActivities: this.state.companyActivities.map((companyActivityIterated: CompanyActivity) =>
          companyActivityIterated.id === companyActivity.id
            ? {
                ...companyActivity,
                attachments: [...companyActivity.attachments, ...attachments],
              }
            : companyActivityIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async deleteCompanyActivityAttachments(companyActivity: CompanyActivity, attachment: Attachment): Promise<void> {
    try {
      await lastValueFrom(this.companyActivityHttpService.removeCompanyActivityAttachment(companyActivity, attachment));
      this.setState({
        companyActivities: this.state.companyActivities.map((companyActivityIterated: CompanyActivity) =>
          companyActivityIterated.id === companyActivity.id
            ? {
                ...companyActivity,
                attachments: companyActivity.attachments.filter(
                  (attachmentIterated: Attachment) => attachmentIterated.id !== attachment.id,
                ),
              }
            : companyActivityIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  addValidatedJqpa(companyActivity: CompanyActivity, validatedJqpa: Attachment): void {
    this.setState({
      companyActivities: this.state.companyActivities.map((companyActivityIterated: CompanyActivity) =>
        companyActivityIterated.id === companyActivity.id
          ? {
              ...companyActivity,
              validatedJqpa,
            }
          : companyActivityIterated,
      ),
    });
  }

  sortCompanyActivities(companyActivities: CompanyActivity[]): CompanyActivity[] {
    return companyActivities.sort((a, b) => {
      if (a.isMainActivity && !b.isMainActivity) {
        return -1;
      } else if (!a.isMainActivity && b.isMainActivity) {
        return 1;
      }
      return a.id - b.id;
    });
  }
}
