import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { retry, tap } from 'rxjs/operators';
import { JobDto, JobStatus } from '@dougs/core/job-dto';
import { FlashMessagesService } from '@dougs/ds';
import { JobApiHttpService } from './job-api.http';

@Injectable({
  providedIn: 'root',
})
export class JobApiService {
  private retryCount = 0;
  constructor(
    private readonly jobApiHttpService: JobApiHttpService,
    private readonly flashMessagesService: FlashMessagesService,
  ) {}

  handleJob(job: JobDto, retryCount?: number): Observable<JobDto> {
    this.retryCount = 0;
    return this.jobApiHttpService.get(job._id).pipe(
      tap((job: JobDto) => {
        if (retryCount && this.retryCount > retryCount) {
          return;
        }
        this.retryCount++;
        if (job.status === JobStatus.SUCCEEDED) {
          const result = this.getLastInfo(job)?.result;

          if (result?.message) {
            this.flashMessagesService.show(result.message.userMessage, {
              timeout: result.message.duration || 5000,
              type: result.message.type,
            });
          }
        } else if (job.status === JobStatus.FAILED) {
          const error = this.getLastInfo(job)?.error;

          if (error?.message) {
            this.flashMessagesService.show(error.message.userMessage, {
              timeout: 5000,
              type: 'error',
            });
          }

          throw new Error('Job failed');
        } else {
          throw 'continue';
        }
      }),
      retry({
        delay: (error) => {
          if (error !== 'continue') {
            throw error;
          }

          return timer(1000);
        },
      }),
    );
  }

  public getLastInfo(job: JobDto): any {
    const info = job.attempts?.length && job.attempts[job.attempts.length - 1].info;

    if (!info) {
      return undefined;
    }

    try {
      return JSON.parse(info);
    } catch (e) {
      return info;
    }
  }
}
