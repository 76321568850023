import { Job } from '../dto';

const getDefaults = (): Job => ({
  uuid: '',
  enabledAt: new Date('2021-01-14'),
  priority: 1,
  handleAttemptCount: 1,
  metadata: {
    message: {
      userMessage: '',
      type: 'info',
      duration: 1000,
    },
    error: {
      userMessage: '',
    },
  },
  id: 1,
  type: '',
  data: '',
  handledAt: new Date('2021-01-14'),
  updatedAt: new Date('2021-01-14'),
  createdAt: new Date('2021-01-14'),
  subtype: '',
  dueDate: new Date('2021-01-14'),
  pushedToQueueAt: new Date('2021-01-14'),
  handleStartedAt: new Date('2021-01-14'),
  hash: '',
  pushableAt: new Date('2021-01-14'),
  effectiveDueDate: new Date('2021-01-14'),
});

export const getJobMock = (p?: Partial<Job>): Job => ({
  ...getDefaults(),
  ...p,
});
