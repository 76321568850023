import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyActivity } from '@dougs/company/dto';
import { AppConfig } from '@dougs/core/config';
import { Attachment } from '@dougs/core/files';

@Injectable({
  providedIn: 'root',
})
export class CompanyActivityHttpService {
  constructor(private readonly http: HttpClient) {}

  addCompanyActivity(companyId: number): Observable<CompanyActivity> {
    return this.http.post<CompanyActivity>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/company-activities`,
      {
        companyId,
      },
    );
  }

  getCompanyActivities(companyId: number): Observable<CompanyActivity[]> {
    return this.http.get<CompanyActivity[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/company-activities`,
    );
  }

  updateCompanyActivity(companyActivity: CompanyActivity): Observable<CompanyActivity> {
    return this.http.post<CompanyActivity>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyActivity.companyId}/company-activities/${companyActivity.id}`,
      companyActivity,
    );
  }

  deleteCompanyActivity(companyActivity: CompanyActivity): Observable<CompanyActivity> {
    return this.http.delete<CompanyActivity>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyActivity.companyId}/company-activities/${companyActivity.id}`,
    );
  }

  addCompanyActivityAttachment(companyActivity: CompanyActivity, file: File): Observable<Attachment> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post<Attachment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyActivity.companyId}/company-activities/${companyActivity.id}/attachments?filename=${file.name}&type=attachment`,
      formData,
    );
  }

  removeCompanyActivityAttachment(companyActivity: CompanyActivity, attachment: Attachment): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyActivity.companyId}/company-activities/${companyActivity.id}/attachments/${attachment.id}`,
    );
  }
}
