import { JobDto } from '../dto';
import { JobStatus } from '../enums';

const getDefaults = (): JobDto => ({
  _id: '',
  type: '',
  data: {},
  attemptCount: 1,
  maxAttemptCount: 1,
  status: JobStatus.SUCCEEDED,
  succeededAt: new Date('2021-01-14'),
  attempts: [{ success: true, info: '{"message":{"userMessage":"","type":"info","duration":1000}}' }],
  updatedAt: new Date('2021-01-14'),
  createdAt: new Date('2021-01-14'),
});

export const getJobApiMock = (p?: Partial<JobDto>): JobDto => ({
  ...getDefaults(),
  ...p,
});
